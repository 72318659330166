import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import { PropertyGrid } from "../components/Property/PropertyGrid"
import { HeroImage } from "../components/Hero/HeroImage"
import { Icon } from "../components/IconFactory/Icons"
import {
  CurveCarveTop,
  CurveCarveBottom,
  CurveBulgeBottom,
} from "../components/Curves/Curve"
import { Container, Row, Col, Button } from "react-bootstrap"
import { HR } from "../components/Typography/Seperators"

import { FeatureImage } from "../components/Feature/FeatureImage"
import { AccordionCollection } from "../components/Accordion/AccordionCollection"
import { Newsletter } from "../components/Misc/Newsletter"
import { GuideTile } from "../components/Guides/GuideTiles"
import { ArticleSnippetHome } from "../components/Article/ArticleSnippetHome"
import { CallMeBack } from "../components/Misc/CallMeBack"
import { truncate } from "../utils/truncate"

const HubSector = ({ data: { contentfulHubSector: sector, guide } }) => {
  return (
    <Layout>
      <SEO
        title={sector.seoTitle || sector.title}
        description={
          sector.summary && truncate(sector.summary.summary, 350, true)
        }
      />
      <HeroImage
        fluid={sector.heroPhoto && sector.heroPhoto.fluid}
        variant={sector.key}
        curveBottom={<CurveCarveTop variant="primary" />}
      >
        <Icon name={sector.key} />
        <span>{sector.subtitle}</span>
        <h1>{sector.title}</h1>
      </HeroImage>

      <div className="bg-primary text-white text-center py-5">
        <Container>
          <Row>
            <Col md={8} className="mx-auto">
              <h2>{sector.headline}</h2>
              <HR />
              {sector.summary && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: sector.summary.childMarkdownRemark.html,
                  }}
                />
              )}
              <CallMeBack />
            </Col>
          </Row>
        </Container>
      </div>
      <CurveCarveBottom variant="primary" />

      <div>
        <Container className="py-5 text-center">
          <Row>
            <Col>
            <h2>{sector.fundamentalsTitle ? sector.fundamentalsTitle : sector.subtitle + " Market"}</h2>
            </Col>
          </Row>
          <Row>
            {sector.fundamentals &&
              sector.fundamentals.map(feature => {
                return <FeatureImage key={feature.id} feature={feature} />
              })}
          </Row>
        </Container>
      </div>

      <CurveCarveTop variant="light" />
      <div className="bg-light py-5">
        <Container className="pb-5 text-center">
          <Row>
            <Col>
              <h2>{sector.subtitle} Investment Opportunities</h2>
            </Col>
          </Row>
        </Container>
        <PropertyGrid properties={sector.property} />

        <Container>
          <Row>
            <Col className="text-center">
              <Button
                as={Link}
                variant="secondary"
                size="lg"
                to="/search-result"
              >
                Search Investments
              </Button>
            </Col>
          </Row>
        </Container>
      </div>

      <CurveCarveTop variant="primary" background="light" />

      <AccordionCollection
        items={sector.accordions}
        variant={sector.key}
        staffCtaImage={sector.staffCtaImage}
      />

      <CurveCarveTop variant="light" background="primary" />

      <div className="bg-light pt-5">
        {(sector.guidance || guide) && (
          <Container>
            <Row className="text-center pb-5">
              <Col>
                <h2>{sector.subtitle} - Guidance &amp; Tips</h2>
              </Col>
            </Row>

            <Row>
              {sector.guidance &&
                sector.guidance.map(article => {
                  return (
                    <Col
                      sm={6}
                      lg={guide ? 3 : 4}
                      key={article.id}
                      className="mx-auto"
                    >
                      <ArticleSnippetHome
                        triangle
                        article={article}
                        variant={sector.key}
                      />
                    </Col>
                  )
                })}
              {guide && (
                <Col sm={6} lg={3} className="mx-auto">
                  <GuideTile guide={guide} />
                </Col>
              )}
            </Row>
          </Container>
        )}
      </div>
      <CurveBulgeBottom variant="light" />

      <Newsletter />
    </Layout>
  )
}

export const query = graphql`
  query HubSectorDetailQuery($id: String) {
    contentfulHubSector(id: { eq: $id }) {
      ...HubSectorFragment
      property {
        ...PropertyListFragment
      }
    }
    guide: contentfulGuide(sector: { id: { eq: $id } }) {
      ...GuideFragment
    }
  }
`

export default HubSector
